<template>
  <article>
    <h1>솔루션 & 서비스</h1>
    <ul class="gnb">
      <router-link to="/sub2_a" exact-active-class="active" tag="li">Platform</router-link>
      <router-link to="/sub2_b" exact-active-class="active" tag="li">DashBoard</router-link>
      <router-link to="/sub2_c" exact-active-class="active" tag="li">GIS</router-link>
      <router-link to="/sub2_d" exact-active-class="active" tag="li">Mobile</router-link>
      <router-link to="/sub2_e" exact-active-class="active" tag="li" class="on">Open GIS</router-link>
    </ul>
    <section class="sub2">
      <h2>OPEN GIS</h2>
      <div class="s_all">
        <div class="s_img">
          <img src="/img/ser_e1.jpg" alt="" class="on">
          <img src="/img/ser_e2.jpg" alt="">
          <img src="/img/ser_e3.jpg" alt="">
          <img src="/img/ser_e4.jpg" alt="">
          <img src="/img/ser_e5.jpg" alt="">
          <img src="/img/ser_e6.jpg" alt="">
        </div>
        <div class="s_txt">
          <h3>Mapmind Open GIS</h3>
          <div class="s_content1">
            <h4>주요기능</h4>
            <p>- 배경지도 전환 (VWorld, 네이버, 다음, 구글)</p>
            <p>&nbsp;</p>
            <p>- 자전거도로, 교통상황, 기상정보(미세먼지 등)</p>
            <p>&nbsp;</p>
            <p>- 거리 / 면적 측정 기능</p>
            <p>&nbsp;</p>
            <p>- <a href="https://opengis.mapmind.kr" target="_blank" style="color: #2196f3; text-decoration: none; font-weight: 600;">https://opengis.mapmind.kr</a></p>
          </div>
          <div class="s_content2">
            <h4>특징</h4>
            <p>- Mapmind GIS API를 활용한 GIS 서비스로</p>
            <p>&nbsp;&nbsp;일부 공간시각화 예시 기능을 제공합니다.</p>
          </div>
        </div>
      </div>
      <div class="container">
        <i class="fas fa-caret-left g_left"></i>
        <div class="gallery_box">
          <ul class="gallery">
            <li class="on"><img src="/img/ser_e1.jpg" alt=""></li>
            <li><img src="/img/ser_e2.jpg" alt=""></li>
            <li><img src="/img/ser_e3.jpg" alt=""></li>
            <li><img src="/img/ser_e4.jpg" alt=""></li>
            <li><img src="/img/ser_e5.jpg" alt=""></li>
            <li><img src="/img/ser_e6.jpg" alt=""></li>
          </ul>
        </div>
        <i class="fas fa-caret-right g_right"></i>
      </div>
    </section>
  </article>
</template>
<script>
export default {
  mounted(){
    //슬라이드 이미지 
    $(document).on("click", ".gallery li", function(){
      var a = $(this).index();
      $('.gallery li').removeClass('on')
      $('.gallery li').eq(a).addClass('on')
      $('.s_img img').removeClass('on')
      $('.s_img img').eq(a).addClass('on')
    });
    //왼쪽 아이콘 누를시
    var b=0;
    $(document).on('click','.g_left',function(){
      if(b>=1) b--;
        $('.gallery li').css('left', -11*b+'vw')
    })
    //오른쪽 아이콘 누를시
    $(document).on('click','.g_right',function(){
      if(b<=0) b++;
        $('.gallery li').css('left', -11*b+'vw')
    })
    //gnb 메뉴 누를시 다시 초기화
    $(document).on('click','.gnb li',function(){
      b=0;
    })
  }
}
</script>
